import { AvailableLocales } from '../../../../localization';
import { UserRole } from '../../auth-mo';

export type GetPortfolioChartPayload = {
  readonly accountId: string;
  readonly portfolioId: string;
  readonly period?: string;
};

export type GetPortfolioChartResponse =
  | {
      /**
       * Portfolio id (suitable for fe)
       */
      readonly id: string;

      /**
       * Project line
       */
      readonly project: Coordinates;

      /**
       * NAVS line
       */
      readonly lineNavs: Coordinates;

      /**
       * LineDD (dont know what this line represents)
       */
      readonly lineDd: Coordinates;

      /**
       * Drawdown line
       */
      readonly lineDrawdown: Coordinates;

      /**
       * Navs for requested period
       */
      readonly navs: number;

      /**
       * Profit for requested period
       */
      readonly profit: number;

      /**
       * Expected revenue
       */
      readonly currentExpectedRevenue: number;

      /**
       * Current drawdown
       */
      readonly currentDrawdown: number;

      /**
       * Drawdown duration
       */
      readonly currentDrawdownDuration: number;

      /**
       * Deviation
       */
      readonly currentDeviation: number;

      /**
       * Last date
       */
      readonly date: string;

      /**
       * General navs
       */
      readonly commonNavs: number;
    }
  | undefined;

type Coordinates = ReadonlyArray<{
  readonly x: string; // date string
  readonly y: number; // value at the date
}>;

export type GetPortfolioFramesResponse = {
  readonly id: string;
  readonly name: string;
  readonly preferences: {
    readonly all: number;
    readonly date: string;
    readonly week: number;
    readonly year: number;
    readonly month: number;
    readonly quarter: number;
  };
  readonly description: string;
  readonly status: 0 | 1;
};

export type GetPortfolioIncomeF1Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
  readonly limitCount: number;
};

export type GetPortfolioIncomeF1Response = ReadonlyArray<{
  readonly CAGR_Fact: number;
  readonly DailyReturn: number;
  readonly Date: string;
  readonly DaysDrawDown: number;
  readonly Drawdown: number;
  readonly FreeCash: number;
  readonly LineDD: number;
  readonly Margin: number;
  readonly Max_DrawDown: number;
  readonly NAVS: number;
  readonly NAVS_ALL: number;
  readonly NAVS_DAY: number;
  readonly NAVS_MONTH: number;
  readonly NAVS_QUARTER: number;
  readonly NAVS_WEEK: number;
  readonly NAVS_YEAR: number;
  readonly PROFIT_ALL: number;
  readonly PROFIT_MONTH: number;
  readonly PROFIT_QUARTER: number;
  readonly PROFIT_WEEK: number;
  readonly PROFIT_YEAR: number;
  readonly Profitability: number;
  readonly Project: number;
  readonly STD_Dev_Real: number;
  readonly UnallocatedCapital: number;
  readonly Weight_in_ACCT: number;
}>;

export type GetPortfolioIncomeF2Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
};

export type GetPortfolioIncomeF2Response = GetPortfolioIncomeF1Response;

export type SendReportPayload = {
  readonly type: string;
  readonly accountId: string;
  readonly portfolioId: string;
  readonly fullName: string;
  readonly email: string;
  readonly start: string;
  readonly end: string;
  readonly reportType: string;
  readonly isSentFromCO?: boolean;
};

export type SetMessageReadStatusPayload = {
  readonly role: UserRole;
  readonly roleLoginEmail: string;
  readonly messageId: string;
  readonly readMark: number;
};

type AccountStatus = 'disconnected' | 'new' | 'active' | 'untuned';

type PortfolioStatus = 'OPENED' | 'CLOSED';

export type ManagerP3Response = ReadonlyArray<{
  readonly UUID: string;
  readonly Topic: string;
  readonly Message: string;
  readonly DateTime: string;
  readonly ReadMark: boolean;
  readonly RowNumber: number;
}>;

export type ManagerP3Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;

  readonly lang: AvailableLocales;
};

export type ManagerP5Response = ReadonlyArray<{
  readonly Priority: string;
  readonly Topic: string;
  readonly Content: string;
  readonly hyperTextIndex: number;
  readonly link: string;
}>;

export type ManagerP5Payload = {
  readonly role: UserRole;
  readonly language: AvailableLocales;
  readonly topicLimit: number;
};

export type ManagerP4_1Payload = ManagerP3Payload;

export type ManagerP4_1_1Payload = {
  readonly roleLoginEmail?: string;
  readonly role?: UserRole;
  readonly requestId: string;
  readonly newStatus: 'new' | 'process' | 'closed' | string;
};

export type ManagerP4Payload = ManagerP3Payload;

export type ManagerP4Response = {
  readonly ResponsePanelCount: number;
  readonly Panels: [RequestsPanel, AccountsPanel, CapitalPanel];
};

export enum ReactingPanelObjectTypes {
  Requests = 'Requests',
  Capital = 'Capital',
  Accounts = 'Accounts',
}

type RequestsPanel = {
  readonly Ordinal: 1;
  readonly ObjectID: ReactingPanelObjectTypes.Requests;
  readonly ObjectName: ReactingPanelObjectTypes.Requests;
  readonly Settings: RequestsPanelSettings;
  readonly Color: string;
};

type RequestsPanelSettings = {
  readonly TotalRequests: number;
  readonly NewRequests: number;
  readonly Color: string;
};

export type AccountsPanel = {
  readonly Ordinal: 2;
  readonly ObjectID: ReactingPanelObjectTypes.Accounts;
  readonly ObjectName: ReactingPanelObjectTypes.Accounts;
  readonly Settings: AccountsPanelSettings;
  readonly Color: string;
};

type AccountsPanelSettings = {
  readonly ConfiguredAccounts: number;
  readonly NeedConfguredAccounts: number;
  readonly Color: string;
};

export type CapitalPanel = {
  readonly Ordinal: 3;
  readonly ObjectID: ReactingPanelObjectTypes.Capital;
  readonly ObjectName: ReactingPanelObjectTypes.Capital;
  readonly Settings: CapitalPanelSettings;
  readonly Color: string;
};

type CapitalPanelSettings = {
  readonly SumUnlocatedCapital: number;
  readonly AccountsFreeCapital: number;
  readonly Color: string;
};

type Request = {
  readonly ClientEmail: string;
  readonly ClientName: string;
  readonly DateTime: string;
  readonly Topic: string;
  readonly RequestText: string;
  readonly RequestID: string;
};

export type ManagerP4_1Response = {
  readonly NewRequest: ReadonlyArray<Request>;
  readonly ProcessRequest: ReadonlyArray<Request>;
  readonly ClosedRequest: ReadonlyArray<Request>;
};

export type ManagerP4_3Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
};

export type ManagerP4_3Response = ReadonlyArray<{
  readonly ClientNameOwner: string;
  readonly ClientEmailOwner: string;
  readonly AccountID: string;
  readonly CurrentStrategy: string;
  readonly CurrentStrategyID: string;
  readonly CurrentCapital: number;
  readonly InvestedCapital: number;
  readonly IncomeCapital: number;
  readonly AccountFreeCash: number;
  readonly AccountAction: string;
}>;

export type ManagerP2_1_1Payload = ManagerP4_3Payload;

export type ManagerP2_1_1Response = ReadonlyArray<{
  readonly Email: string;
  readonly Name: string;
  readonly AccountID: string;
  readonly PortfolioID: string;
  readonly PortfolioName: string;
  readonly TypeReport: string;
  readonly TypeReportCreated: string;
  readonly Period: string;
  readonly ReportDate: string;
  readonly Status: boolean;
}>;

export type ManagerP1Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
};

export type ManagerP1Response = {
  readonly Accounts: number;
  readonly Portfolios: number;
  readonly SummaryCapital: number;
};

export type ManagerP2Payload = ManagerP1Payload;

export type ManagerP2Response = ReadonlyArray<{
  readonly ClientEmailOwner: string;
  readonly ClientNameOwner: string;
  readonly AccountID: string;
  readonly AccountStatus: AccountStatus;
  readonly AccountWight: number;
  readonly AccountCapital: number;
  readonly AccountAction: string;
  readonly AccountUnlocatedCapitalPortfolio: number;
  readonly AccountStrategy: string;
  readonly AccountStrategyID: string;
  readonly AccountStrategyOpen: string;
  readonly AccountFreeCash: number;
  readonly AccountCurrentCapital: number;
  readonly PortfolioOwners: number;
  readonly ManagementFee: number;
  readonly PerformanceFee: number;
  readonly Portfolios: ReadonlyArray<{
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
    readonly PortfolioOnAccountID: string;
    readonly PortfolioID: string;
    readonly PortfolioOpen: string;
    readonly PortfolioName: string;
    readonly PortfolioStatus: PortfolioStatus;
    readonly PortfolioStartWeight: number;
    readonly PortfolioCurrentWeight: number;
    readonly PortfolioInvestCapital: number;
    readonly PortfolioAction: string;
    readonly PortfolioFreeCash: number;
    readonly PortfolioCurrentCapital: number;
    readonly PortfolioUnlocatedCapital: number;
  }>;
}>;

export type ManagerP2_2_1_3_f1Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
  readonly limitCount: number;
};

export type ManagerP2_2_1_3_f2Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
};

export type ManagerP2_2_1_4_fPayload = {
  readonly acctId: string;
  readonly portfolioId: string;
  readonly weightPortfolioIdNumeric: number;
  readonly unlocatedCap: number;
};

export type ManagerP2_2_1_4_pPayload = ManagerP2_2_1_4_fPayload;

export type ManagerP2_2_1_5Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
  readonly fromDate: string;
  readonly toDate: string;
};

export type ManagerP2_2_1_6Payload = {
  readonly acctId: string;
  readonly portfolioId: string;
};

export type ManagerP4_2Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
};

type Account = {
  readonly AccountID: string;
  readonly AccountType: string;
  readonly DateOfAppearance: string;
  readonly CurrentStrategy: string;
  readonly CurrentCapital: number;
  readonly TradingPermissions: string;
  readonly AccountAction: string;
};

export type ManagerP4_2Response = ReadonlyArray<{
  readonly ClientNameOwner: string;
  readonly ClientEmailOwner: string;
  readonly Accounts: ReadonlyArray<Account>;
}>;

export type ManagerP2_2_1_4_fResponse = ReadonlyArray<{
  readonly ISIN: string;
  readonly Name: string;
  readonly Percent: number;
  readonly PositionValue: number;
  readonly Symbol: string;
  readonly UConid: number;
}>;

export type ManagerP2_2_1_4_pResponse = ReadonlyArray<PortfolioAllocation>;

type PortfolioAllocation = {
  readonly Symbol: string;
  readonly Name: string;
  readonly ISIN: string;
  readonly Logo: string;
  readonly Class: 'Stock' | 'ETF';
  readonly ExpectedROI: number;
  readonly ReservedCapital: string;
  readonly BreakEven: number;
  readonly OptionsGain: string;
  readonly TargetPrice: string;
  readonly TargetPricePercent: string;
  readonly DateStartProject: string;

  readonly Charts: AllocationChartModel;

  readonly PositionsInProject: {
    readonly opened: ReadonlyArray<PositionModel>;
    readonly closed: ReadonlyArray<PositionModel>;
  };
};

type AllocationChartModel = {
  readonly activeColor: 'green' | 'red';
  readonly chartData: ReadonlyArray<AllocationChartDataModel>;
  readonly activeDotCoords: { readonly x: string; readonly y: number };
  readonly min: number;
  readonly max: number;
};

type AllocationChartDataModel = {
  readonly offsetY?: number;
  readonly activeY?: number;
  readonly inactiveY?: number;
  readonly allY: number;
  readonly allX: string;
};

type PositionModel = Pick<RawPortfolioAllocation, 'PositionsInProject'>['PositionsInProject'][0] & {
  readonly id: number;
};

type RawPortfolioAllocation = {
  readonly Symbol: string;
  readonly Name: string;
  readonly ISIN: string;
  readonly Logo: string;
  readonly Class: 'Stock' | 'ETF';
  readonly ExpectedROI: number;
  readonly ReservedCapital: string;
  readonly BreakEven: number;
  readonly OptionsGain: string;
  readonly TargetPrice: string;
  readonly TargetPricePercent: string;
  readonly DateStartProject: string;
  readonly Charts: ReadonlyArray<{ readonly Date: string; readonly Close: number }>;
  readonly PositionsInProject: ReadonlyArray<{
    readonly PositionStatus: 'OPENED' | 'CLOSED';
    readonly DateOpened: string;
    readonly AssetClassIB: 'STK' | 'OPT';
    readonly OrderType: 'SOLD' | 'BOUGHT' | 'ASSIGNED' | 'CLOSED';
    readonly TypePosition: 'Option PUT' | 'Option CALL' | 'Stoks';
    readonly ExpirationDate: string;
    readonly DTEorHolds: string;
    readonly EarnedIncome: string;
    readonly RecivedIncome: string;
    readonly OpenPrice: string;
    readonly OpenPriceValue: string;
    readonly LastPriceValue: string;
    readonly LastPrice: string;
    readonly Commissions: string;
    readonly CommissionsValue: number;
    readonly Return: string;
    readonly ReturnValue: string;
  }>;
};

export type ManagerP2_2_1_5Response = ReadonlyArray<{
  readonly Name: string;
  readonly Symbol: string;
  readonly Proceed: number;
  readonly 'Buy/Sell': 'BUY' | 'SELL';
  readonly Quantity: number;
  readonly ReportDate: string;
  readonly TradePrice: number;
  readonly IBCommission: number;
  readonly Sub_I_Weight: number;
  readonly ClientAccountId: string;
}>;

export type ManagerP2_2_1_6Response = ReadonlyArray<{
  readonly Date: string;
  readonly Summ: number;
  readonly Balance: number;
  readonly TypeCash: string;
}>;

type ManagerP2_3_4_getResponse = {
  readonly ClientNameOwner: string;
  readonly ClientEmailOwner: string;
  readonly AccountID: string;
  readonly ManagementFee: number;
  readonly ManagementFrequency: string;
  readonly PerformanceFee: number;
  readonly PerformanceFrequency: string;
  readonly HighWaterMark: number;
};

export type ManagerGetAccountFeesPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly acctId: string;
};

export type ManagerGetAccountFeesResponse = ManagerP2_3_4_getResponse;

export type ManagerP2_3_4_setPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly feesDoc: ManagerP2_3_4_getResponse;
};

interface ManagerP2_4_5Payload extends FullDefaultPayload {
  readonly newNamePortfolio: string;
}

export type ManagerP2_3_1Payload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly acctId: string;
  readonly sumWithdraw: number;
  readonly targetDate: string; // 'YYYY-MM-DD'
};

export type ManagerGetAccountPortfoliosSnapshotPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly acctId: string;
};

export type ManagerGetAccountPortfoliosSnapshotResponse = ReadonlyArray<ManagerGetAccountPortfolioSnapshotResponse>;

export type ManagerSetAccountEntitiesPayload = {
  readonly role: UserRole;
  readonly roleLoginEmail: string;
  readonly portfoliosDoc?: PortfoliosDoc;
  readonly feesDoc?: FeesDoc;
  readonly strategyDoc?: StrategyDoc;
};

type PortfoliosDoc = {
  readonly ClientEmailOwner: string;
  readonly AccountID: string;
  readonly ReOpenPortfolios: ReadonlyArray<{
    readonly PortfolioID: string;
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
  }>;
  readonly OpenPortfolios: ReadonlyArray<{
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
    readonly PortfolioStartCapital: number;
  }>;
  readonly ClosePortfolios: ReadonlyArray<{
    readonly PortfolioID: string;
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
  }>;
  readonly ChangePortfolios: ReadonlyArray<{
    readonly PortfolioID: string;
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
  }>;
};

type FeesDoc = {
  readonly ClientEmailOwner: string;
  readonly AccountID: string;
  readonly ManagementFee: number;
  readonly ManagementFrequency: string;
  readonly PerformanceFee: number;
  readonly PerformanceFrequency: string;
  readonly HighWaterMark: number;
};

type StrategyDoc = {
  readonly StrategyID: string;
  readonly StrategyName: string;
  readonly AccountID: string;
  readonly ClientEmailOwner: string;
  readonly CAGR: number;
  readonly MaxDrawDown: number;
};

export type ManagerSetAccountEntitiesResponse = {
  readonly portfoliosStatus?: 'OK' | 'ERROR';
  readonly feesStatus?: 'OK' | 'ERROR';
  readonly strategiesStatus?: 'OK' | 'ERROR';
};

export type ManagerGetAccountStrategyResponse = {
  readonly AccountID: string;
  readonly AccountType: string;
  readonly CurrentCapital: number;
  readonly ClientNameOwner: string;
  readonly CurrentStrategy: string;
  readonly ClientEmailOwner: string;
  readonly DateOfAppearance: string;
  readonly CurrentStrategyID: string;
  readonly TradingPermissions: string;
  readonly Broker: string;
};

export type ManagerGetAccountStrategyPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly acctId: string;
};

export type ManagerGetStrategiesPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly lang: AvailableLocales;
};

export type ManagerGetStrategiesResponse = ReadonlyArray<{
  readonly StrategyID: string;
  readonly StrategyName: string;
  readonly Requirements: StrategyRequirements;
  readonly About: string;
  readonly CAGR: number;
  readonly MaxDrawDown: number;
}>;

export type StrategyRequirements = {
  readonly TradingPermissions: string;
  readonly AccountType: string;
  readonly MinCapital: number;
  readonly Broker: string;
};

type FullDefaultPayload = {
  readonly roleLoginEmail: string;
  readonly role: UserRole;
  readonly acctId: string;
  readonly portfolioId: string;
};

interface ManagerP2_4_1Payload extends FullDefaultPayload {
  readonly sumDeposit: number;
}
export type { ManagerP2_4_1Payload };

interface ManagerP2_4_2Payload extends FullDefaultPayload {
  readonly sumWithdraw: number;
}
export type { ManagerP2_4_2Payload };

interface ManagerP2_4_3Payload extends Omit<FullDefaultPayload, 'portfolioId'> {
  readonly fromPortfolioId: string;
  readonly toPortfolioId: string;
  readonly sumTransfer: number;
}
export type { ManagerP2_4_3Payload };

export type ManagerP2_4_4_getPayload = {
  readonly acctId: string;
  readonly portfolioId: string;
};

export type ManagerP2_4_4_closePayload = FullDefaultPayload;

interface ManagerP2_4_5Payload extends FullDefaultPayload {
  readonly newNamePortfolio: string;
}
export type { ManagerP2_4_5Payload };

export type ManagerGetAccountPortfoliosPayload = ManagerGetAccountStrategyPayload;

export type ManagerGetAccountPortfoliosResponse = {
  readonly ClientNameOwner: string;
  readonly ClientEmailOwner: string;
  readonly AccountID: string;
  readonly AccountUnlocatedCapitalPortfolio: number;
  readonly Portfolios: ReadonlyArray<{
    readonly PortfolioMember: string;
    readonly PortfolioOwner: string;
    readonly PortfolioName: string;
    readonly PortfolioID: string;
    readonly PortfolioStatus: PortfolioStatus;
    readonly PortfolioStartWeight: number;
    readonly PortfolioCurrentWeight: number;
    readonly PortfolioInvestCapital: number;
    readonly PortfolioCurrentCapital: number;
  }>;
};

export type ManagerGetAccountPortfolioSnapshotPayload = {
  readonly acctId: string;
};

export type ManagerGetAccountPortfolioSnapshotResponse = {
  readonly AccountID: string;
  readonly PortfolioID: string;
  readonly PortfolioName: string;
  readonly PortfolioOwner: string;
  readonly PortfolioMember: string;
  readonly PortfolioDateOpen: string;
  readonly PortfolioDateClose: string;
  readonly PortfolioStartWeight: number;
  readonly PortfolioStartCapital: number;
  readonly PortfolioCurrentWeight: number;
  readonly PortfolioInvestCapital: number;
  readonly PortfolioCurrentCapital: number;
};

export type ManagerSetAccountStrategyPayload = {
  readonly roleLoginEmail: string;
  readonly role: string;
  readonly strategyDoc: StrategyDoc;
  readonly portfoliosDoc: PortfoliosDoc;
};

export type ManagerSetSelectedTableIdsPayload = ReadonlyArray<string>;

export type ManagerSetAccountPortfoliosPayload = Pick<FullDefaultPayload, 'roleLoginEmail' | 'role'> & {
  readonly portfoliosDoc: PortfoliosDoc;
};

export type ManagerGetPortfolioPayload = {
  readonly email: string;
};

export type ManagerSendBulkReportPayload = {
  readonly reportType: 'monthly' | 'yearly';
  readonly start: string;
  readonly end: string;
  readonly clients: ReadonlyArray<{ email: string; name: string; accountId: string }>;
};

export type ManagerInitResponse = {
  readonly reactingPanel: ManagerP4Response;
  readonly clientRequests: ManagerP4_1Response;
  readonly messages: ManagerP3Response;
};
