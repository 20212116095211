import React, { FC } from 'react';

import { Accordion } from '../../../../../../../accordion';
import { AccordionBodyProps } from '../../../../../../../accordion/components/accordion-body/accordion-body.types';
import { Button } from '../../../../../../../button';
import { Modal } from '../../../../../../../modal/modal.component';
import { ModalIds } from '../../../../../../../../contexts/modals/modals.types';
import { SummaryProps } from './summary.types';
import { Typography } from '../../../../../../../../../../../components';
import classNames from 'classnames';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import styles from './summary.module.scss';
import { useTranslation } from 'react-i18next';

dayjs.extend(customParseFormat);

const getComboOrderPrice = (order: SummaryProps['summaryRecommendations'][number]['order']): number => {
  switch (order.orderType.toLowerCase()) {
    case 'lmt': {
      return order.lmtPrice;
    }

    case 'stp': {
      return order.auxPrice;
    }

    case 'mkt': {
      const maxMultiplier = Math.max(...order.contracts.map(({ multiplier }) => multiplier));
      const price = !!order.contracts.length
        ? order.contracts.reduce((acc, cur) => {
            const expression =
              ((+cur.price * cur.ratio * cur.multiplier) / maxMultiplier) * (cur.side === 'BUY' ? 1 : -1);
            return (acc ?? 0) + expression;
          }, 0)
        : 0;
      return price;
    }

    default: {
      return 0;
    }
  }
};

const getOPTorSTKPrice = (order: SummaryProps['summaryRecommendations'][number]['order']): number => {
  switch (order.orderType.toLowerCase()) {
    case 'lmt': {
      return order.lmtPrice;
    }

    case 'stp': {
      return order.auxPrice;
    }

    case 'mkt': {
      return order.contracts[0] ? +order.contracts[0].price : 0;
    }

    default: {
      return 0;
    }
  }
};

/**
 * Summary component
 * @description Content for the Summary stage of the Place order flow.
 *
 * @author Oleksii Medvediev
 * @param { SummaryProps } props - SummaryProps defined in the './summary.types.ts'
 * @category Components
 */
const Summary: FC<SummaryProps> = ({ onClose, onSubmit, summaryRecommendations }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'management' });

  return (
    <Modal modalId={ModalIds.orderPlace} title={t('modals.order.place.summary.title')}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <Typography.Text variant={'bodyText5'}>{t('modals.order.place.summary.description')}</Typography.Text>
          {(summaryRecommendations ?? []).map(({ account, id, order, orderDescription, symbol }, index) => (
            <Accordion
              key={id.toString() + index}
              id={id}
              title={
                <div className={styles.title}>
                  <Typography.Text variant={'label4'} className={styles.account}>
                    {/* 10 */}
                    {account}
                  </Typography.Text>
                  <div className={styles.orderInfo}>
                    {/* OPT/STK: 18, combo: 2 */}
                    <Typography.Text variant={'h7'}>
                      {order.contracts.length === 1 ? order.contracts[0].symbol : symbol}
                    </Typography.Text>
                    <div className={styles.contractsList}>
                      {order.contracts.map(({ secType, strike, side }, index) => (
                        <div className={styles.contract} key={secType + index}>
                          <Typography.Text variant={'bodyText3'} className={styles.side}>
                            {/* OPT/STK: 14, combo: 25, 36 */}
                            {order.contracts.length === 1 ? order.side : side}
                          </Typography.Text>
                          <Typography.Text variant={'bodyText3'}>
                            {/* STK: 'Stock', OPT: 17, combo: 17, 28*/}
                            {secType.toLowerCase() === 'stk' ? 'Stock' : secType}
                          </Typography.Text>
                          {/* OPT: 21, STK: 21, 32 */}
                          {secType.toLowerCase() !== 'stk' && (
                            <Typography.Text variant={'bodyText3'}>Strike {strike}</Typography.Text>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              }
              body={{
                items:
                  order.contracts.length !== 1
                    ? // COMBO
                      [
                        ...order.contracts.map(
                          ({ secType, side, strike, lastTradeDateOrContractMonth }) =>
                            ({
                              text: (
                                <div className={classNames(styles.bodyItem, styles.combo)}>
                                  <div className={styles.itemRow}>
                                    <Typography.Text variant={'bodyText5'} className={styles.label}>
                                      {t('modals.order.place.summary.financialInstrument')}
                                    </Typography.Text>
                                    {/* 17 */}
                                    <Typography.Text variant={'bodyText5'}>{secType}</Typography.Text>
                                  </div>
                                  <div className={styles.itemRow}>
                                    <Typography.Text variant={'bodyText5'} className={styles.label}>
                                      {t('modals.order.place.summary.operation')}
                                    </Typography.Text>
                                    {/* 25 */}
                                    <Typography.Text variant={'bodyText5'}>{side}</Typography.Text>
                                  </div>
                                  <div className={styles.itemRow}>
                                    <Typography.Text variant={'bodyText5'} className={styles.label}>
                                      Exp. Date
                                    </Typography.Text>
                                    {/* 23 */}
                                    <Typography.Text variant={'bodyText5'}>
                                      {!!lastTradeDateOrContractMonth &&
                                        dayjs(lastTradeDateOrContractMonth, 'YYYYMMDD')
                                          .format('DD MMMM YY')
                                          .toLowerCase()}
                                    </Typography.Text>
                                  </div>
                                  {strike && (
                                    <div className={styles.itemRow}>
                                      <Typography.Text variant={'bodyText5'} className={styles.label}>
                                        Strike
                                      </Typography.Text>
                                      {/* 21 */}
                                      <Typography.Text variant={'bodyText5'}>{strike}</Typography.Text>
                                    </div>
                                  )}
                                </div>
                              ),
                            }) as AccordionBodyProps['items'][number],
                        ),
                        {
                          text: (
                            <div className={styles.bodyItem}>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.orderType')}
                                </Typography.Text>
                                {/* 12 */}
                                <Typography.Text variant={'bodyText5'}>{order.orderType}</Typography.Text>
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.quantity')}
                                </Typography.Text>
                                {/* 11 */}
                                <Typography.Text variant={'bodyText5'}>{order.quantity}</Typography.Text>
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.price')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={getComboOrderPrice(order)}
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.sum')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={
                                    getComboOrderPrice(order) *
                                    Math.max(...order.contracts.map(({ multiplier }) => multiplier)) *
                                    order.quantity
                                  }
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.commission')}
                                </Typography.Text>
                                {/* orderDescription.commission/47 */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={+orderDescription.commission ?? 0}
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.total')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={
                                    getComboOrderPrice(order) *
                                      Math.max(...order.contracts.map(({ multiplier }) => multiplier)) *
                                      order.quantity +
                                    +orderDescription.commission
                                  }
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.account')}
                                </Typography.Text>
                                {/* 10 */}
                                <Typography.Text variant={'bodyText5'}>{order.account}</Typography.Text>
                              </div>
                            </div>
                          ),
                        },
                      ]
                    : // OPTION or ACTION
                      [
                        {
                          text: (
                            <div className={styles.bodyItem}>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.financialInstrument')}
                                </Typography.Text>
                                {/* 17 */}
                                <Typography.Text variant={'bodyText5'}>{order.contracts[0].secType}</Typography.Text>
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.operation')}
                                </Typography.Text>
                                {/* 14 */}
                                <Typography.Text variant={'bodyText5'}>{order.side}</Typography.Text>
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.orderType')}
                                </Typography.Text>
                                {/* 12 */}
                                <Typography.Text variant={'bodyText5'}>{order.orderType}</Typography.Text>
                              </div>
                              {order.contracts[0].secType.toLowerCase() !== 'stk' && (
                                <>
                                  <div className={styles.itemRow}>
                                    <Typography.Text variant={'bodyText5'} className={styles.label}>
                                      Exp. Date
                                    </Typography.Text>
                                    {/* 23 */}
                                    <Typography.Text variant={'bodyText5'}>
                                      {!!order.contracts[0].lastTradeDateOrContractMonth &&
                                        dayjs(order.contracts[0].lastTradeDateOrContractMonth, 'YYYYMMDD')
                                          .format('DD MMMM YY')
                                          .toLowerCase()}
                                    </Typography.Text>
                                  </div>
                                  {order.contracts[0].strike && (
                                    <div className={styles.itemRow}>
                                      <Typography.Text variant={'bodyText5'} className={styles.label}>
                                        Strike
                                      </Typography.Text>
                                      {/* 21 */}
                                      <Typography.Text variant={'bodyText5'}>
                                        {order.contracts[0].strike}
                                      </Typography.Text>
                                    </div>
                                  )}
                                </>
                              )}
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.quantity')}
                                </Typography.Text>
                                {/* 11 */}
                                <Typography.Text variant={'bodyText5'}>{order.quantity}</Typography.Text>
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.price')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={getOPTorSTKPrice(order)}
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.sum')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={
                                    getOPTorSTKPrice(order) *
                                    order.contracts[0].multiplier *
                                    order.quantity *
                                    (order.side === 'BUY' ? 1 : -1)
                                  }
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.commission')}
                                </Typography.Text>
                                {/* orderDescription/36 */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={+orderDescription.commission ?? 0}
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.total')}
                                </Typography.Text>
                                {/* func */}
                                <Typography.Number
                                  variant={'bodyText5'}
                                  currency
                                  round={2}
                                  value={
                                    getOPTorSTKPrice(order) *
                                      order.contracts[0].multiplier *
                                      order.quantity *
                                      (order.side === 'BUY' ? 1 : -1) +
                                    +orderDescription.commission
                                  }
                                />
                              </div>
                              <div className={styles.itemRow}>
                                <Typography.Text variant={'bodyText5'} className={styles.label}>
                                  {t('modals.order.place.summary.account')}
                                </Typography.Text>
                                {/* 10 */}
                                <Typography.Text variant={'bodyText5'}>{order.account}</Typography.Text>
                              </div>
                            </div>
                          ),
                        },
                      ],
              }}
            />
          ))}
        </div>
        <div className={styles.controls}>
          <Button type={'button'} variant={'outline'} size={'large'} onClick={onClose}>
            <Typography.Text variant={'bodyText2'}>{t('modals.order.place.summary.cancel')}</Typography.Text>
          </Button>
          <Button type={'button'} variant={'main'} size={'large'} onClick={onSubmit}>
            <Typography.Text variant={'bodyText2'}>{t('modals.order.place.summary.send')}</Typography.Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { Summary };
